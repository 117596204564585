// font-face
@mixin webfont($family, $file, $weight: normal, $style: normal) {
	@font-face {
	  font-family: $family;
	  src: url('./fonts/'+ $family + '/' + $file + '.woff2') format('woff2'),
		url('./fonts/'+ $family + '/'  + $file + '.woff') format('woff');
	  font-weight: $weight;
	  font-style: $style;
	  font-display: swap;
	}
  }

  @include webfont('MTSText', 'MTSText-Regular', 400);
  @include webfont('MTSText', 'MTSText-Medium', 500);
  @include webfont('MTSText', 'MTSText-Bold', 700);
  @include webfont('MTSText', 'MTSText-Black', 900);

  @include webfont('MTSWide', 'MTSWide-Regular', 400);
  @include webfont('MTSWide', 'MTSWide-Medium', 500);
  @include webfont('MTSWide', 'MTSWide-Bold', 700);
  @include webfont('MTSWide', 'MTSWide-Black', 900);

  @include webfont('MTSCompact', 'MTSCompact-Regular', 400);
  @include webfont('MTSCompact', 'MTSCompact-Medium', 500);
  @include webfont('MTSCompact', 'MTSCompact-Bold', 700);
  @include webfont('MTSCompact', 'MTSCompact-Black', 900);
